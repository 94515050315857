<template>
  <div class="layout">
    <div class="flex-one" v-html="res.list[0].richText"></div>
  </div>
</template>
<script>
  export default {
    props: ['res'],
  }
</script>
<style lang="scss" scoped>
  @import './tpl.scss';
  .flex-one {
    display: block;
    width: 100%;
    min-height: 110px;
    overflow: auto;
    > img {
      width: 100%;
      height: 100%;
    }
  }
</style>
